import * as nprogress from 'nprogress';
import axios from 'axios';

import 'nprogress/nprogress.css';

const apiURL = process.env.GATSBY_API_URL;

async function setContractStartAPI(email: string, contract_start: string) {
  nprogress.start();

  const data = {
    email,
    contract_start,
  };

  const urlSetContractStart = `${apiURL}/setContractStart`;
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  let success = false;
  let res;
  try {
    res = await axios.post(urlSetContractStart, JSON.stringify(data));
    console.log(res.data);
    success = true;
  } catch (error) {
    console.log(error);
  }

  nprogress.done();
  if (success) {
    return res.data;
  }
  return success;
}

export default setContractStartAPI;
