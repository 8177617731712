import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Form, Button, Image } from 'react-bootstrap';
import { Link, navigate } from 'gatsby';
import { Formik } from 'formik';
import {
  errorMessage,
  leftColumn,
  rightColumn,
  smallText,
  inputFieldHeader1,
  inputFieldHeader2,
  inputFieldHeader3,
  inputField,
  buyButton,
  validatedField,
  companyName,
  pad,
  cartContainer,
  chooseTerminalDiv,
  terminalImageStyle,
  hiddenTextField,
  hiddenTextFieldNoMargin,
  buyButtonCart,
  bigPricingText,
  smallPricingText,
  totalPrice,
  miniPad,
  smallPad,
  pricingElements,
  pricingTextElements,
  totalCost,
  summary,
  addButton,
  subtractButton,
} from './FlowContact.module.scss';
import useLanguageStore from '../LanguageStore/LanguageStore';
import useFlowCompanyDataStore from '../FlowStore/FlowCompanyDataStore';
import useFlowUserStore from '../FlowStore/FlowUserStore';
import useFlowProgressStore from '../FlowStore/FlowProgressStore';
import useFlowTerminalStore from '../FlowStore/FlowTerminalStore';
import ProgressBar from '../ProgressBar/ProgressBar';
import contactSchema from '../Validations/contactValidation';
import cardsImage from '../../../static/images/terminal-static-small.jpg';
import useFlowProviderStore from '../FlowStore/FlowProviderStore';
import { findBankName } from '../Offer/OfferHelper';
import FlowTopText from './FlowTopText';
import FlowBottom from './FlowBottom';
import updateUserAPI from './apiFunctions/updateUserAPI';
import setContractStartAPI from './apiFunctions/setContractStartAPI';

type LanguageContent = {
  leftHeroHeader: string;
  leftHeroBody: string;
  topText1: string;
  topText2: string;
  topText3: string;
  // form
  login: string;
  loginLink: string;
  clientInformation: string;
  personFirstname: string;
  personLastname: string;
  CompanyInformation: string;
  CompanyName: string;
  CompanyNumber: string;
  CompanyAdress: string;
  CompanyZipcode: string;
  CompanyCity: string;
  Email: string;
  Phone: string;
  Password: string;
  ConfirmPW: string;
  contractStartHeader: string;
  contractStart: string;
  termsText: string;
  termsLink: string;
  privacyLink: string;
  buyButton: string;
  // Cart
  cart: string;
  summary: string;
  numberOfTerminals: string;
  creditCardFee: string;
  debitCardFee: string;
  transactionFee: string;
  programmingFee: string;
  oneTimeFee: string;
  shipping: string;
  noTerminalChosen: string;
  currencyPerMonth: string;
  totalCost: string;
};

const content: { [name: string]: LanguageContent } = {};
content.English = {
  leftHeroHeader: 'Your quotes are ready!',
  leftHeroBody: 'We need a way to contact you. Please create an account.',
  topText1:
    'You only need to put your contact details on this page and press Buy Now.',
  topText2: 'We need this information to create your contract.',
  topText3: 'We will be contacting you by phone to complete your order.',
  // Form
  login: 'Already registered? Click ',
  loginLink: 'here to login.',
  clientInformation: 'Client Information',
  personFirstname: 'First name',
  personLastname: 'Last name',
  CompanyInformation: 'Informacion sobre tu empresa',
  CompanyName: 'Company Name',
  CompanyNumber: 'Company Registration Number',
  CompanyAdress: 'Address',
  CompanyZipcode: 'Zip code',
  CompanyCity: 'City',
  Email: 'Email',
  Phone: 'Phone',
  Password: 'Password',
  ConfirmPW: 'Confirm Password',
  contractStartHeader: 'Contract Start Date',
  contractStart: 'Fecha para empezar el contrato',
  termsText: 'By singning up, you agree to our',
  termsLink: ' Terms,',
  privacyLink: ' Data Policy and Cookies.',
  buyButton: 'Buy Now',
  // Cart
  cart: 'Your cart',
  summary: 'Summary',
  numberOfTerminals: 'Number of terminals ordered',
  creditCardFee: 'Credit card processing fee',
  debitCardFee: 'Debit card processing fee',
  transactionFee: 'Transaction fee',
  programmingFee: 'Programming fee',
  oneTimeFee: 'One time fee',
  shipping: 'Shipping',
  noTerminalChosen: 'No terminal choosen, go to preferences.',
  currencyPerMonth: 'mxn/month',
  totalCost: 'Total cost',
};
content.Español = {
  leftHeroHeader: 'COMPANY NAME',
  leftHeroBody: 'Para ver las ofertas, necesitas una cuenta con PuntoTerminal.',
  topText1:
    'Solo tienes que entrar tus datos en esta pagina y luego hacer clic en Contratar.',
  topText2: 'Necesitamos esos datos para poder hacer un contrato.',
  topText3: 'Le contactaremos por telefono para terminar tu pedido.',
  // Form
  login: '¿Tienes una cuenta? Click ',
  loginLink: 'aqui para logear.',
  clientInformation: 'Datos del cliente',
  personFirstname: 'Nombre',
  personLastname: 'Apellidos',
  CompanyInformation: 'Datos de la empresa',
  CompanyName: 'Nombre de la empresa',
  CompanyNumber: 'RFC CURP',
  CompanyAdress: 'Dirección',
  CompanyZipcode: 'Codigo postal',
  CompanyCity: 'Ciudad',
  Email: 'Correo electrónico',
  Phone: 'Teléfono',
  Password: 'Contraseña',
  ConfirmPW: 'Confirmar Contraseña',
  contractStartHeader: 'Fecha para empezar el contrato',
  contractStart: 'Fecha para empezar el contrato',
  termsText: 'Con el acto de crear el usuario, aceptas nuestros ',
  termsLink: ' Términos y condiciones,',
  privacyLink: ' Política y Cookies.',
  buyButton: 'Contratar',
  // Cart
  cart: 'Tu carrito',
  summary: 'Resumen',
  numberOfTerminals: 'Cantidad de terminales',
  creditCardFee: 'Tarifa tarjetas credito',
  debitCardFee: 'Tarifa tarjetas debito',
  transactionFee: 'Tarifa por transaccion',
  programmingFee: 'Tarifa por programar terminal',
  oneTimeFee: 'Tarifa unico',
  shipping: 'Envio',
  noTerminalChosen: 'No terminal eligido, ve a preferencias.',
  currencyPerMonth: 'mxn/mes',
  totalCost: 'Costo total',
};

const FlowContact = (props) => {
  const language = useLanguageStore((state) => state.language);
  let choosenLanguage: LanguageContent;
  language === 'Español'
    ? (choosenLanguage = content.Español)
    : (choosenLanguage = content.English);

  // Import states
  const flowProgress = useFlowProgressStore((state) => state.flowProgress);

  const companyRFCName: string = useFlowCompanyDataStore(
    (state) => state.companyRFCName
  );

  const contractStart: string = useFlowCompanyDataStore(
    (state) => state.contractStart
  );

  const personFirstname: string = useFlowUserStore(
    (state) => state.personFirstname
  );
  const personLastname: string = useFlowUserStore(
    (state) => state.personLastname
  );
  const password: string = useFlowUserStore((state) => state.password);
  const passwordConfirm: string = useFlowUserStore(
    (state) => state.passwordConfirm
  );
  const email: string = useFlowUserStore((state) => state.email);
  const phone: string = useFlowUserStore((state) => state.phone);
  const address: string = useFlowUserStore((state) => state.address);
  const postal_code: string = useFlowUserStore((state) => state.postal_code);
  const city: string = useFlowUserStore((state) => state.city);

  const mobileTerminals: number = useFlowTerminalStore(
    (state) => state.mobileTerminals
  );
  const staticTerminals: number = useFlowTerminalStore(
    (state) => state.staticTerminals
  );
  const inalambricoTerminals: number = useFlowTerminalStore(
    (state) => state.inalambricoTerminals
  );

  // import update state functions
  const nextFlowPage = useFlowProgressStore((state) => state.nextFlowPage);

  const setCompanyRFCName = useFlowCompanyDataStore(
    (state) => state.setCompanyRFCName
  );

  const setContractStart = useFlowCompanyDataStore(
    (state) => state.setContractStart
  );

  const setPersonFirstname = useFlowUserStore(
    (state) => state.setPersonFirstname
  );
  const setPersonLastname = useFlowUserStore(
    (state) => state.setPersonLastname
  );
  const setPassword = useFlowUserStore((state) => state.setPassword);
  const setPasswordConfirm = useFlowUserStore(
    (state) => state.setPasswordConfirm
  );
  const setEmail = useFlowUserStore((state) => state.setEmail);
  const setPhone = useFlowUserStore((state) => state.setPhone);
  const setAddress = useFlowUserStore((state) => state.setAddress);
  const setPostalCode = useFlowUserStore((state) => state.setPostalCode);
  const setCity = useFlowUserStore((state) => state.setCity);

  const chosenProvider = useFlowProviderStore((state) => state.chosenProvider);
  const tasaDescuentoCredito = useFlowProviderStore(
    (state) => state.tasaDescuentoCredito
  );
  const tasaDescuentoDebito = useFlowProviderStore(
    (state) => state.tasaDescuentoDebito
  );
  const totalYearCost = useFlowProviderStore((state) => state.totalYearCost);
  const monthCost = Math.round(totalYearCost / 12);

  const setMobileTerminals = useFlowTerminalStore(
    (state) => state.setMobileTerminals
  );
  const setStaticTerminals = useFlowTerminalStore(
    (state) => state.setStaticTerminals
  );
  const setInalambricoTerminals = useFlowTerminalStore(
    (state) => state.setInalambricoTerminals
  );

  const chosenProviderName = findBankName(chosenProvider);

  const isBrowser = typeof window !== 'undefined';
  const [width, setWidth] = useState<number>();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    setWidth(window.innerWidth);

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 426;
  const isTablet = width <= 768;
  const isDesktop = width >= 1208;

  // if (flowProgress < 3) {
  //   return (
  //     <Container>
  //       <Link to="/preferencias">Empieza con tus preferencias</Link>
  //     </Container>
  //   );
  // }

  // Date Picker - This state is used to fix the placeholder text
  const [inputType, setInputType] = useState('text');

  let chosenTerminalNumber = 0;
  function chooseTerminal(values, handleBlur, setFieldValue) {
    if (mobileTerminals > 0) {
      chosenTerminalNumber = mobileTerminals;
      return (
        <>
          <Col xs={4} sm={4} md={4} lg={4} className={chooseTerminalDiv}>
            <div className={miniPad} />
            <Button
              className={subtractButton}
              variant="primary"
              name="mobileTerminals"
              onClick={() => {
                let value = values.mobileTerminals;
                if (value >= 2) {
                  value -= 1;
                  setFieldValue('mobileTerminals', value);
                  setMobileTerminals(value);
                }
              }}
              onBlur={handleBlur}
            >
              -
            </Button>
          </Col>

          <Col xs={4} sm={4} md={4} lg={4} className={chooseTerminalDiv}>
            <div className={miniPad} />
            <p>{values.mobileTerminals}</p>
          </Col>

          <Col xs={4} sm={4} md={4} lg={4} className={chooseTerminalDiv}>
            <div className={miniPad} />
            <Button
              className={addButton}
              variant="primary"
              name="mobileTerminals"
              onClick={() => {
                let value = values.mobileTerminals;
                value += 1;
                setFieldValue('mobileTerminals', value);
                setMobileTerminals(value);
              }}
              onBlur={handleBlur}
            >
              +
            </Button>
          </Col>
        </>
      );
    }
    if (staticTerminals > 0) {
      chosenTerminalNumber = staticTerminals;
      return (
        <>
          <Col xs={4} sm={4} md={4} lg={4} className={chooseTerminalDiv}>
            <div className={smallPad} />
            <Button
              className={subtractButton}
              variant="primary"
              name="staticTerminals"
              onClick={() => {
                let value = values.staticTerminals;
                if (value >= 2) {
                  value -= 1;
                  setFieldValue('staticTerminals', value);
                  setStaticTerminals(value);
                }
              }}
              onBlur={handleBlur}
            >
              -
            </Button>
          </Col>

          <Col xs={4} sm={4} md={4} lg={4} className={chooseTerminalDiv}>
            <div className={smallPad} />
            <p>{values.staticTerminals}</p>
          </Col>

          <Col xs={4} sm={4} md={4} lg={4} className={chooseTerminalDiv}>
            <div className={smallPad} />
            <Button
              className={addButton}
              variant="primary"
              name="staticTerminals"
              onClick={() => {
                let value = values.staticTerminals;
                value += 1;
                setFieldValue('staticTerminals', value);
                setStaticTerminals(value);
              }}
              onBlur={handleBlur}
            >
              +
            </Button>
          </Col>
        </>
      );
    }
    if (inalambricoTerminals > 0) {
      chosenTerminalNumber = inalambricoTerminals;
      return (
        <>
          <Col xs={4} sm={4} md={4} lg={4} className={chooseTerminalDiv}>
            <div className={miniPad} />
            <Button
              className={subtractButton}
              variant="primary"
              name="inalambricoTerminals"
              onClick={() => {
                let value = values.inalambricoTerminals;
                if (value >= 2) {
                  value -= 1;
                  setFieldValue('inalambricoTerminals', value);
                  setInalambricoTerminals(value);
                }
              }}
              onBlur={handleBlur}
            >
              -
            </Button>
          </Col>

          <Col xs={4} sm={4} md={4} lg={4} className={chooseTerminalDiv}>
            <div className={miniPad} />
            <p>{values.inalambricoTerminals}</p>
          </Col>

          <Col xs={4} sm={4} md={4} lg={4} className={chooseTerminalDiv}>
            <div className={miniPad} />
            <Button
              className={addButton}
              variant="primary"
              name="inalambricoTerminals"
              name="inalambricoTerminals"
              onClick={() => {
                let value = values.inalambricoTerminals;
                value += 1;
                setFieldValue('inalambricoTerminals', value);
                setInalambricoTerminals(value);
              }}
              onBlur={handleBlur}
            >
              +
            </Button>
          </Col>
        </>
      );
    }
    return (
      <Link to="/preferencias" className={chooseTerminalDiv}>
        <p>{choosenLanguage.noTerminalChosen}</p>
      </Link>
    );
  }

  function terminalError(touched, errors) {
    if (errors.mobileTerminals) {
      return (
        <>
          {touched.mobileTerminals && errors.mobileTerminals ? (
            <div className={errorMessage}>{errors.mobileTerminals}</div>
          ) : null}
        </>
      );
    }
    if (errors.staticTerminals) {
      return (
        <>
          {touched.staticTerminals && errors.staticTerminals ? (
            <div className={errorMessage}>{errors.staticTerminals}</div>
          ) : null}
        </>
      );
    }
    if (errors.inalambricoTerminals) {
      return (
        <>
          {touched.inalambricoTerminals && errors.inalambricoTerminals ? (
            <div className={errorMessage}>{errors.inalambricoTerminals}</div>
          ) : null}
        </>
      );
    }
  }

  let filler;
  if (isTablet === false) {
    filler = (
      <div className={hiddenTextField}>
        <div className={pad} />
        <h5>{choosenLanguage.summary}</h5>
      </div>
    );
  }
  let pricingFiller;
  // Shown on desktop and mobile, not tablet.
  if (isMobile === true || isDesktop === true) {
    pricingFiller = <p className={hiddenTextFieldNoMargin}>0</p>;
  }

  return (
    <>
      <FlowTopText
        text1={choosenLanguage.topText1}
        text2={choosenLanguage.topText2}
        text3={choosenLanguage.topText3}
      />
      <ProgressBar />
      <Container id="FlowContainer">
        <div className={pad} />
        <Row>
          <Formik
            initialValues={{
              companyRFCName,
              address,
              postal_code,
              city,
              mobileTerminals,
              staticTerminals,
              inalambricoTerminals,
              personFirstname,
              personLastname,
              email,
              phone,
              password,
              passwordConfirm,
              contractStart,
            }}
            validationSchema={contactSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              // Set values to zustand state
              setCompanyRFCName(values.companyRFCName);
              setAddress(values.address);
              setPostalCode(values.postal_code);
              setCity(values.city);
              setMobileTerminals(values.mobileTerminals);
              setStaticTerminals(values.staticTerminals);
              setInalambricoTerminals(values.inalambricoTerminals);
              setPersonFirstname(values.personFirstname);
              setPersonLastname(values.personLastname);
              setEmail(values.email);
              setPhone(values.phone);
              setPassword(values.password);
              setPasswordConfirm(values.passwordConfirm);
              setContractStart(values.contractStart);
              // Go to next page
              nextFlowPage();
              const res1 = await setContractStartAPI(
                values.email,
                values.contractStart
              );
              const res = await updateUserAPI(); // Need to send data again to update terminal selection
              if (res) {
                navigate('/gracias-compra');
                setSubmitting(false);
              } else {
                alert(
                  'Error actualizar su usuario, contactanos en info@puntoterminal.mx o 56 4238 4307 y le ayudamos.'
                );
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              validateField,
              setFieldTouched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Col xs={12} sm={12} md={12} lg={6} className={leftColumn}>
                  <div className={inputFieldHeader1}>
                    <p>
                      <strong>{choosenLanguage.CompanyInformation}</strong>
                    </p>
                  </div>

                  <div className={inputField}>
                    <Form.Group controlId="companyRFCName">
                      <Form.Control
                        type="text"
                        placeholder={choosenLanguage.CompanyName}
                        name="companyRFCName"
                        data-testid="companyRFCName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.companyRFCName}
                        className={
                          touched.companyRFCName && !errors.companyRFCName
                            ? validatedField
                            : null
                        }
                      />

                      {touched.companyRFCName && errors.companyRFCName ? (
                        <div className={errorMessage}>
                          {errors.companyRFCName}
                        </div>
                      ) : null}
                    </Form.Group>

                    <Form.Group controlId="address">
                      <Form.Label> </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={choosenLanguage.CompanyAdress}
                        name="address"
                        data-testid="address"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.address}
                        className={
                          touched.address && !errors.address
                            ? validatedField
                            : null
                        }
                      />

                      {touched.address && errors.address ? (
                        <div className={errorMessage}>{errors.address}</div>
                      ) : null}
                    </Form.Group>

                    <Form.Group controlId="postal_code">
                      <Form.Label> </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder={choosenLanguage.CompanyZipcode}
                        name="postal_code"
                        data-testid="postal_code"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.postal_code}
                        className={
                          touched.postal_code && !errors.postal_code
                            ? validatedField
                            : null
                        }
                      />

                      {touched.postal_code && errors.postal_code ? (
                        <div className={errorMessage}>{errors.postal_code}</div>
                      ) : null}
                    </Form.Group>

                    <Form.Group controlId="city">
                      <Form.Label> </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={choosenLanguage.CompanyCity}
                        name="city"
                        data-testid="city"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.city}
                        className={
                          touched.city && !errors.city ? validatedField : null
                        }
                      />

                      {touched.city && errors.city ? (
                        <div className={errorMessage}>{errors.city}</div>
                      ) : null}
                    </Form.Group>
                  </div>

                  <div className={inputFieldHeader2}>
                    <p>
                      <strong>{choosenLanguage.clientInformation}</strong>
                    </p>
                  </div>
                  <div className={inputField}>
                    <Form.Group controlId="personFirstname">
                      <Form.Control
                        type="text"
                        placeholder={choosenLanguage.personFirstname}
                        name="personFirstname"
                        data-testid="personFirstname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.personFirstname}
                        className={
                          touched.personFirstname && !errors.personFirstname
                            ? validatedField
                            : null
                        }
                      />

                      {touched.personFirstname && errors.personFirstname ? (
                        <div className={errorMessage}>
                          {errors.personFirstname}
                        </div>
                      ) : null}
                    </Form.Group>

                    <Form.Group controlId="personLastname">
                      <Form.Label> </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={choosenLanguage.personLastname}
                        name="personLastname"
                        data-testid="personLastname"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.personLastname}
                        className={
                          touched.personLastname && !errors.personLastname
                            ? validatedField
                            : null
                        }
                      />

                      {touched.personLastname && errors.personLastname ? (
                        <div className={errorMessage}>
                          {errors.personLastname}
                        </div>
                      ) : null}
                    </Form.Group>

                    <Form.Group controlId="email">
                      <Form.Label> </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={choosenLanguage.Email}
                        name="email"
                        data-testid="email"
                        value={email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        className={
                          touched.email && !errors.email ? validatedField : null
                        }
                      />

                      {touched.email && errors.email ? (
                        <div className={errorMessage}>{errors.email}</div>
                      ) : null}
                    </Form.Group>

                    <Form.Group controlId="phone">
                      <Form.Label> </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={choosenLanguage.Phone}
                        name="phone"
                        data-testid="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        className={
                          touched.phone && !errors.phone ? validatedField : null
                        }
                      />

                      {touched.phone && errors.phone ? (
                        <div className={errorMessage}>{errors.phone}</div>
                      ) : null}
                    </Form.Group>

                    <Form.Group controlId="password">
                      <Form.Label> </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={choosenLanguage.Password}
                        name="password"
                        data-testid="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className={
                          touched.password && !errors.password
                            ? validatedField
                            : null
                        }
                      />

                      {touched.password && errors.password ? (
                        <div className={errorMessage}>{errors.password}</div>
                      ) : null}
                    </Form.Group>

                    <Form.Group controlId="passwordConfirm">
                      <Form.Label> </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder={choosenLanguage.ConfirmPW}
                        name="passwordConfirm"
                        data-testid="passwordConfirm"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.passwordConfirm}
                        className={
                          touched.passwordConfirm && !errors.passwordConfirm
                            ? validatedField
                            : null
                        }
                      />

                      {touched.passwordConfirm && errors.passwordConfirm ? (
                        <div className={errorMessage}>
                          {errors.passwordConfirm}
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>

                  <div className={inputFieldHeader3}>
                    <p>
                      <strong>{choosenLanguage.contractStartHeader}</strong>
                    </p>
                  </div>
                  <div className={inputField}>
                    <Form.Group controlId="contractStart">
                      <Form.Label> </Form.Label>
                      <Form.Control
                        type={inputType}
                        placeholder={choosenLanguage.contractStart}
                        name="contractStart"
                        data-testid="contractStart"
                        value={values.contractStart}
                        onChange={handleChange}
                        onFocus={() => setInputType('date')}
                        onBlur={() => setInputType('text')}
                        className={
                          touched.contractStart && !errors.contractStart
                            ? validatedField
                            : null
                        }
                      />

                      {touched.contractStart && errors.contractStart ? (
                        <div className={errorMessage}>
                          {errors.contractStart}
                        </div>
                      ) : null}
                    </Form.Group>

                    <p className={smallText}>
                      {choosenLanguage.termsText}
                      <Link to="/terminos-y-condiciones">
                        {' '}
                        {choosenLanguage.termsLink}{' '}
                      </Link>
                      <Link to="/privacidad">
                        {' '}
                        {choosenLanguage.privacyLink}{' '}
                      </Link>
                    </p>
                  </div>
                </Col>

                <Col xs={12} sm={12} md={12} lg={6} className={rightColumn}>
                  <div>
                    <p>
                      <strong>{choosenLanguage.cart}</strong>
                    </p>
                    <div className={cartContainer}>
                      <div className={pad} />
                      <h5 className={companyName}>{chosenProviderName}</h5>
                      <Row>
                        <Col xs={12} sm={12} md={12} lg={6}>
                          {filler}
                          <Row>
                            <div className={chooseTerminalDiv}>
                              <Image
                                className={terminalImageStyle}
                                src={cardsImage}
                                alt={props.imgTitle}
                                title={props.imgAlt}
                                rounded
                              />
                            </div>
                            {chooseTerminal(values, handleBlur, setFieldValue)}
                            {terminalError(touched, errors)}
                          </Row>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6}>
                          <Row>
                            <div className={pad} />
                            <h5 className={summary}>
                              {choosenLanguage.summary}
                            </h5>
                            <Col
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              className="col-auto"
                            >
                              <div>
                                <p className={pricingTextElements}>
                                  {choosenLanguage.numberOfTerminals}
                                </p>
                                <p className={pricingTextElements}>
                                  {choosenLanguage.creditCardFee}
                                </p>
                                <p className={pricingTextElements}>
                                  {choosenLanguage.debitCardFee}
                                </p>
                                <p className={pricingTextElements}>
                                  {choosenLanguage.transactionFee}
                                </p>
                                <p className={pricingTextElements}>
                                  {choosenLanguage.oneTimeFee}
                                </p>
                                <p className={pricingTextElements}>
                                  {choosenLanguage.shipping}
                                </p>
                              </div>
                            </Col>
                            <Col
                              xs={6}
                              sm={6}
                              md={6}
                              lg={6}
                              className="text-end"
                            >
                              <p className={pricingElements}>
                                {chosenTerminalNumber}
                              </p>
                              {pricingFiller}
                              <p className={pricingElements}>
                                {tasaDescuentoCredito}%
                              </p>
                              {pricingFiller}
                              <p className={pricingElements}>
                                {tasaDescuentoDebito}%
                              </p>
                              {pricingFiller}
                              <p className={pricingElements}>5 mxn</p>
                              {pricingFiller}
                              <p className={pricingElements}>0 mxn</p>
                              <p className={pricingElements}>0 mxn</p>
                            </Col>
                          </Row>
                        </Col>
                        <Row>
                          <h5 className={totalCost}>
                            {choosenLanguage.totalCost}
                          </h5>
                          <div className={totalPrice}>
                            <div className={miniPad} />
                            <Row>
                              <Col xs={6} sm={6} md={6} lg={6}>
                                <span className={bigPricingText}>
                                  {monthCost}
                                </span>
                              </Col>
                              <Col xs={6} sm={6} md={6} lg={6}>
                                <div className={miniPad} />
                                <div className={miniPad} />
                                <Row>
                                  <span className={smallPricingText}>
                                    {' '}
                                    {choosenLanguage.currencyPerMonth}
                                  </span>
                                </Row>
                                <Row>
                                  {/* <span className={smallPricingText}>
                                    {' '}
                                    por 36 meses
                                  </span> */}
                                </Row>
                              </Col>
                              <div className={miniPad} />
                            </Row>
                          </div>
                        </Row>
                        <div>
                          <Button
                            className={buyButtonCart}
                            variant="success"
                            size="lg"
                            type="submit"
                            disabled={isSubmitting}
                            data-testid="submitButtonCart"
                          >
                            {choosenLanguage.buyButton}
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </div>
                </Col>

                <div>
                  <Button
                    className={buyButton}
                    variant="success"
                    size="lg"
                    type="submit"
                    disabled={isSubmitting}
                    data-testid="submitButtonMain"
                  >
                    {choosenLanguage.buyButton}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Row>
      </Container>
      <div className={pad} />
      <FlowBottom />
    </>
  );
};

export default FlowContact;
