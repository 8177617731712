import * as nprogress from 'nprogress';
import axios from 'axios';
import useFlowUserStore from '../../FlowStore/FlowUserStore';
import useFlowCompanyDataStore from '../../FlowStore/FlowCompanyDataStore';

import 'nprogress/nprogress.css';

const apiURL = process.env.GATSBY_API_URL;

async function updateUserAPI() {
  nprogress.start();

  // Import states
  const { companyRFCName } = useFlowCompanyDataStore.getState();

  // Import states
  const {
    personFirstname,
    personLastname,
    phone,
    email,
    password,
    address,
    postal_code,
    city,
  } = useFlowUserStore.getState();

  const contact_data = {
    // Step 3
    // Contact Form
    name: personFirstname,
    last_name: personLastname,
    email,
    password,
    phone,
    company_name: companyRFCName,
    address,
    postal_code,
    city,
  };

  const urlUpdateUser = `${apiURL}/update`;
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  let success = false;
  try {
    const res = await axios.post(urlUpdateUser, JSON.stringify(contact_data));
    // console.log(res.data);
    success = true;
  } catch (error) {
    console.log(error);
  }

  nprogress.done();
  return success;
}

export default updateUserAPI;
