/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FunctionComponent } from 'react';
import Header from '../komponents/Header/Header';
import Footer from '../komponents/Footer/Footer';
import FlowContact from '../komponents/Flow/FlowContact';

const Contratar: FunctionComponent = () => (
  <>
    <Header />
    <FlowContact />
    <Footer />
  </>
);

export default Contratar;

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content="Contrata Terminal Punto de venta" />
    <title>Contrata Terminal - PuntoTerminal.mx</title>
  </>
);
